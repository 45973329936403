@import "./_mixins.scss";

.space {
  position: relative;
  height: 81px;
}

.header {
  position: fixed;
  padding: 20px 0 21px;
  z-index: 20;
  width: 100%;
  background-color: $black;

  @include breakpoint(m) {
    padding: 10px 0 11px;
    border: none;
  }

  @include dark {
    border-color: $neutrals3;
  }

  .container {
    display: flex;
    align-items: center;

    .button {
      margin-right: 12px;

      @include breakpoint(m) {
        display: none;
      }
    }

    .logo {
      position: relative;
      z-index: 12;
      flex-shrink: 0;
      height: 40px;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      padding-top: 5px;
      /* identical to box height, or 125% */

      text-align: center;
      letter-spacing: -0.01em;
      color: $white;
      margin-left: 5%;

      @include breakpoint(d) {
        margin-left: 5px;
      }

      @include breakpoint(m) {
        margin-left: 5%;
      }
    }

    .divider {
      border-left: 2px solid $white;
      height: 40px;
      margin: 0 32px;

      @include breakpoint(d) {
        // display: none;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    .mobile-nav-button {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      @include breakpoint(d) {
        display: none;
      }

      .bar {
        width: 30px;
        height: 4px;
        background-color: $white;
        margin: 6px 0;
        transition: 0.4s;
      }

      &.open {
        .bar:nth-child(1) {
          transform: rotate(-45deg) translate(-5px, 6px);
        }

        .bar:nth-child(2) {
          opacity: 0;
        }

        .bar:nth-child(3) {
          transform: rotate(45deg) translate(-5px, -6px);
        }
      }
    }

    .nav {
      display: flex;
      margin-right: auto;

      @include breakpoint(d) {
        display: flex;
      }

      @include dark {
        border-color: $neutrals3;
      }
    }

    .link {
      font-weight: 400;
      font-size: 18px;
      line-height: 16px;
      /* identical to box height, or 114% */

      text-align: center;
      /* Neutrals/8 */

      color: $neutrals8;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 32px;
      }
      @include breakpoint(d) {
        font-size: 15px;
        margin-right: 0;
      }
      @include breakpoint(m) {
        font-size: 15px;
        margin-right: 0;
      }
    }
    .linkText:hover {
      font-weight: 400;
      font-size: 18px;
      // color: aquamarine;
      color: #b8a4ff;

      @include breakpoint(d) {
        font-size: 15px; // 모바일 화면에서 더 작은 폰트 크기로 조정
      }
      @include breakpoint(m) {
        font-size: 15px; // 모바일 화면에서 더 작은 폰트 크기로 조정
      }
    }

    .search {
      width: 200px;
      flex-shrink: 0;
      margin-right: 24px;
      position: relative;

      @include breakpoint(d) {
        margin-left: auto;
        display: none;
      }

      @include breakpoint(m) {
        width: 20px;
        display: none;
      }

      .input {
        @include caption-2;
        transition: border-color 0.2s;
        width: 100%;
        height: 40px;
        padding: 0 42px 0 16px;
        background: none;
        border: 2px solid $neutrals6;
        color: $neutrals8;
        border-radius: 8px;

        &::placeholder {
          color: $neutrals4;
        }

        @include dark {
          border-color: $neutrals3;
          color: $neutrals8;
        }

        &:focus {
          border-color: $neutrals4;
        }
      }

      .result {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 42px;

        svg {
          fill: $neutrals4;
          transition: fill 0.2s;
        }

        &:hover {
          svg {
            fill: $blue;
          }
        }
      }
    }

    .notification {
      margin-right: 24px;

      @include breakpoint(m) {
        margin-right: 20px;
      }
    }

    .user {
    }
  }
}
