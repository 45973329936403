@import "../header/mixins.scss";

.width {
  margin-left: 6%;
  margin-right: 6%;
}
.refresh {
  color: red;
  font-size: 20px;
  margin-bottom: 50px;
}
.section {
  padding: 80px 0;
  width: 98vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sectionTemp {
  width: 98vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.box {
  margin-left: auto;
  width: 90%;
  margin-bottom: 2%;
}

.cardGrid {
  display: grid;
  width: 90%;
  margin-left: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 32px;
  column-gap: 32px;
  display: block;

  @include breakpoint(d) {
    margin-left: auto;
    display: block;
    width: 85%;
  }

  @include breakpoint(m) {
    margin-left: auto;
    display: block;
    width: 85%;
  }
}

.card {
  margin-right: 1.5%;
  margin-bottom: 1%;
  display: inline-grid;
  width: 320px;
  height: auto;

  padding: 12px;
  border-radius: 20px;
  box-shadow: 0 64px 64px -48px rgba(31, 47, 70, 0.62);
  background-color: #fcfcfd;
  cursor: pointer;
  &:hover {
    background-color: #f0ee88;
  }

  @include breakpoint(d) {
    margin-left: auto;
    width: 320px;
    height: 380px;
  }

  @include breakpoint(m) {
    margin-left: auto;
    width: 320px;
    height: 380px;
  }
}
.cardImage {
  width: 160px;
  height: 50px;

  @include breakpoint(d) {
    margin-left: auto;
    width: 300px;
    height: 200px;
  }

  @include breakpoint(m) {
    margin-left: auto;
    width: 300px;
    height: 200px;
  }
}
.cardTitle {
  height: 200px;
  font-size: 23px;
  font-weight: 550;
  color: #23262f;
}
.cardGoal {
  margin-left: 5%;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #8063ec;
  line-height: 24px;
  @include breakpoint(d) {
    margin-right: auto;
  }
  @include breakpoint(m) {
    margin-right: auto;
  }
}

.widhdrawal_plus {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #8063ec;
  line-height: 24px;
  @include breakpoint(d) {
    margin-right: auto;
  }
  @include breakpoint(m) {
    margin-right: auto;
  }
}
.widhdrawal_minus {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #fc0808;
  line-height: 24px;
  @include breakpoint(d) {
    margin-right: auto;
  }
  @include breakpoint(m) {
    margin-right: auto;
  }
}

.card:hover .cardGoal {
  font-size: 20px;
  font-weight: 600;
  color: #6540eb;
  line-height: 24px;
}
.cardLeft {
  width: 63px;
  height: 24px;
  border-radius: 4px;
  background-color: #8063ec;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  font-weight: 500;
  color: #f2f3f5;
  @include breakpoint(d) {
    margin-left: auto;
  }
  @include breakpoint(m) {
    margin-left: auto;
  }
}
.card:hover .cardLeft {
  width: 63px;
  height: 24px;
  border-radius: 4px;
  background-color: #6540eb;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  font-weight: 550;
  color: #f2f3f5;
}
.cardDivider {
  height: 1px;
  width: 100%;
  border-radius: 1px;
  background-color: #e6e9ec;
}
.cardArtist {
  font-size: 12px;
  line-height: 20px;
  color: #353945;
}

.check1:hover img {
  content: "./roastore-notice-check2.jpg";
}

.refreshButton {
  width: 200px;
  margin-top: 15px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #167a50;
  color: #167a50;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.periodButton {
  width: 200px;
  margin-top: 15px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #83b410;
  color: #83b410;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.previewButton {
  width: 200px;
  margin-top: 5px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #3498db;
  color: #3498db;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.solscanButton {
  width: 200px;
  margin-top: 5px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #9a1faa;
  color: #9a1faa;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}
